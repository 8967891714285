/**
 * Adds toggle content functionality any button and content which
 * has data-toggle and data-toggle-content attribute. This is imported
 * in button.js and initiated there.
 */
import {Flip} from './flip.js';

/**
 * @param {Flip} contentFlip
 */
function toggleTheContent(contentFlip) {
    contentFlip.play();
}

const eventListenerFunctions = [];

/** [no description; please add] */
function removeListeners() {
    eventListenerFunctions.forEach(({toggleButton, toggleCallBack}) => {
        toggleButton.removeEventListener('click', toggleCallBack);
    });
}

/**
 *
 */
export function initToggleContent() {
    removeListeners();

    const toggleButtons = [...document.querySelectorAll('[data-toggle]')];
    toggleButtons.forEach((toggleButton) => {
        const toggleId = toggleButton.dataset.toggle;
        const toggleContent = document.querySelector(`[data-toggle-content="${toggleId}"]`);

        if (!toggleContent) {
            return;
        }

        const defaultHeight = `${toggleContent.dataset.toggleMinHeight || 0}px`;
        toggleContent.style.height = defaultHeight;

        const contentFlip = new Flip({
            elementToFlip: toggleContent,
            flipProperty: 'height',
            transitionClass: 'dataToggleTransitionClass',
            flipFunction: () => {
                if (!contentFlip.flipped) {
                    toggleContent.style.height = 'auto';
                    toggleContent.classList.add('dataToggle__content--expanded');
                    toggleButton.classList.add('dataToggle__button--expanded');
                } else {
                    toggleContent.style.height = defaultHeight;
                    toggleContent.classList.remove('dataToggle__content--expanded');
                    toggleButton.classList.remove('dataToggle__button--expanded');
                }
            },
        });

        contentFlip.addListener('transitionend', () => {
            toggleContent.classList.remove('dataToggleTransitionClass');
            if (contentFlip.flipped) {
                toggleContent.style.height = 'auto';
            } else {
                toggleContent.style.height = defaultHeight;
            }
        });

        // Create a named function and save it so that
        // we can remove the correct event listener
        /**
         *
         */
        function toggleCallBack() {
            toggleTheContent(contentFlip);
        }
        eventListenerFunctions.push({toggleButton, toggleCallBack});

        toggleButton.addEventListener('click', toggleCallBack);
    });
}
